import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const LegalNoticeContent = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Legal Notice')}
          lang={pageContext.lang}
          meta={[
            {
              property: `robots`,
              content: 'noindex,nofollow',
            },
          ]}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Legal Notice')}
            descriptionText={t(
              'Do you want to know who is responsible for this website?'
            )}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <LegalNoticeContent>
                <p>
                  <b>Address:</b>
                </p>
                esaqa GmbH
                <br />
                Tiergartenstr. 13
                <br />
                91247 Vorra
                <br />
                Germany
                <br />
                <br />
                Managing Director: Sascha Pfeiffer
                <br />
                Commercial Register: Nuremberg Registry Court HRB 37978
                <br />
                USt-IdNr.: DE333156173
                <br />
                <br />
                <br />
                <p>
                  <b>Email:</b>
                </p>
                support@psono.com <br />
                <br />
                <p>
                  <b>Limitation of liability for internal content</b>
                </p>
                <p>
                  The content of our website has been compiled with meticulous
                  care and to the best of our knowledge. However, we cannot
                  assume any liability for the up-to-dateness, completeness or
                  accuracy of any of the pages.
                </p>
                <p>
                  Pursuant to section 7, para. 1 of the TMG (Telemediengesetz –
                  Tele Media Act by German law), we as service providers are
                  liable for our own content on these pages in accordance with
                  general laws. However, pursuant to sections 8 to 10 of the
                  TMG, we as service providers are not under obligation to
                  monitor external information provided or stored on our
                  website. Once we have become aware of a specific infringement
                  of the law, we will immediately remove the content in
                  question. Any liability concerning this matter can only be
                  assumed from the point in time at which the infringement
                  becomes known to us.
                </p>
                <br />
                <p>
                  <b>Limitation of liability for external links</b>
                </p>
                <p>
                  Our website contains links to the websites of third parties
                  („external links“). As the content of these websites is not
                  under our control, we cannot assume any liability for such
                  external content. In all cases, the provider of information of
                  the linked websites is liable for the content and accuracy of
                  the information provided. At the point in time when the links
                  were placed, no infringements of the law were recognizable to
                  us. As soon as an infringement of the law becomes known to us,
                  we will immediately remove the link in question.
                </p>
                <br />
                <p>
                  <b>Copyright</b>
                </p>
                <p>
                  The content and works published on this website are governed
                  by the copyright laws of Germany. Any duplication, processing,
                  distribution or any form of utilization beyond the scope of
                  copyright law shall require the prior written consent of the
                  author or authors in question.
                </p>
                <p>
                  All image licenses have been bought through{' '}
                  <a href="https://fotolia.com/" rel="nofollow noopener">
                    Fotolia
                  </a>
                </p>
              </LegalNoticeContent>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
